<template>
  <!-- <content-with-sidebar class="blog-wrapper"> -->
  <div>

    <b-row>
      <b-col
        md="12"
        class="mb-2"
      >
        <b-row>
          <b-col>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="filters.q"
                placeholder="Rechercher  une propriété"
                class="search-product"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col>
            <!-- Per Page Button -->
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="`Affichage ${perPageValue}`"
              right
              variant="flat-primary"
              class="mx-1"
            >
              <b-dropdown-item
                v-for="(item,index) in perPages"
                :key="`per-${index}`"
                :active="perPageValue===item"
                @click.prevent="perPageValue = item"
              >
                {{ item }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-col
        v-for="(item,index) in proprietesDataPaginate"
        :key="item.name"
        md="4"
        class="mb-1"
      >
        <b-link
          id="enlace-blog-0"
          :to="{name:'espace-coproprietaire.detail-propriete', params:{id:item.id}, replace:true}"
          aria-label="blog"
        >
          <div class="tarjeta-blog ">
            <div class="imagen-tarjeta-blog">
              <b-img
                v-if="item.type_propriete.title==='Immeuble'"
                id="imagen-blog-0"
                class="lazy"
                :src="item.appartements.length!==0?`${SITE_URL}storage/uploads/${item.appartements[0].propriete_image.image}`: `${SITE_URL}storage/default/21.jpg`"
                :alt="item.name"
              />
              <b-img
                v-else
                id="imagen-blog-0"
                class="lazy"
                :src="item.propriete_images.length !== 0 ? `${SITE_URL}storage/uploads/${item.propriete_images[0].image}` : `${SITE_URL}storage/default/img-1.jpg`"
                :alt="item.name"
              />
            </div>
            <div class="titulo-tarjeta-blog">

              <b-badge
                pill
                class="mr-75 mb-1"
                :variant="categorieProprieteColor(item.type_propriete.title)"
              >
                {{ item.type_propriete.title }}
              </b-badge>
              <span
                id="badge-blog-0"
                class="badge badge-pill badge-blog  float-right text-white bg-success"
              ><i class="icofont-ui-calendar" />
                Libre</span>
              <b-media
                v-if="!item.is_multiple_proprietaire"
                no-body
              >
                <b-media-aside
                  vertical-align="center"
                  class="mr-50 mb-1"
                >
                  <b-avatar
                    href="javascript:void(0)"
                    size="24"
                    variant="light-primary"
                    :text="avatarText(item.proprietaire_actuel.name)"
                  />
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted mr-50">de</small>
                  <small>
                    <b-link class="text-body">{{ item.proprietaire_actuel.name }}</b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted font-weight-bold">{{ item.created_at }}</small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small
                    v-if="item.status==='Non publié'"
                    class="text-danger"
                  >{{ item.status }}</small>
                  <small
                    v-else
                    class="text-success"
                  >{{ item.status }}</small>
                </b-media-body>
              </b-media>
              <span v-else>Multi propriétaires</span>

              <p
                id="titulo-blog-0 mb-1"
                :style="{fontWeight:'bold'}"
                class="h6 text-capitalize"
              >{{ item.name }}</p>

              <div class="text-center">
                <b-button
                  :id="`popover-reactive-${index}`"
                  ref="button"
                  variant="outline-primary"
                  size="sm"
                  pill
                  class="btn-cart move-cart"
                >
                  <span>Actions</span>
                </b-button>
              </div>
              <b-popover
                ref="popover"
                variant="secondary"
                :target="`popover-reactive-${index}`"
                triggers="hover"
                placement="top"
                container="my-container"
              >
                <div>
                  <!-- button -->
                  <!-- button -->
                  <b-button
                    v-if="item.type_propriete.title=='Immeuble'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    pill
                    variant="gradient-info"
                    class=""
                    :to="{ name:'espace-coproprietaire.modifier-immeuble', params:{id:item.id} }"
                  >
                    Modifier
                  </b-button>
                  <b-button
                    v-if="item.type_propriete.title=='Villa'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    pill
                    variant="gradient-info"
                    class=""
                    :to="{ name: 'espace-coproprietaire.modifier-villa', params: { id: item.id } }"
                  >
                    Modifier
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    pill
                    :variant="item.status === 'Publié' ? 'gradient-warning' : 'gradient-success'"
                    class=""
                    @click.prevent="publishProperty(item)"
                  >
                    {{ item.status==='Publié'?'Non publié':'Publié' }}
                  </b-button>
                  <b-button
                    v-if="item.type_propriete.title !== 'Immeuble'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    pill
                    variant="gradient-primary"
                    class=""
                    @click.prevent="copyPixCodeClick(item.id)"
                  >
                    Copier
                  </b-button>
                </div>
              </b-popover>

            </div>
          </div>
        </b-link>

      </b-col>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            v-model="filters.page"
            align="center"
            :total-rows="total"
            :per-page="filters.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>

      </b-col>
    </b-row>
  </div>
  <!--/ blogs -->

  <!-- </content-with-sidebar> -->
</template>

<script>
import {
  BRow, BCol, BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BBadge, BInputGroup, BInputGroupAppend, BPagination, BDropdown,
  BDropdownItem, BPopover, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { kFormatter, avatarText } from '@core/utils/filter'

import Ripple from 'vue-ripple-directive'
import {
  onMounted, ref, watch,
} from '@vue/composition-api'

// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'

// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { $themeConfig } from '@themeConfig'
import Swal from 'sweetalert2'
import useAlertNotification from '@/composables/notification/useAlert'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
    BLink,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BPopover,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const user = JSON.parse(store.state.authStore.user)
    const { SITE_URL } = $themeConfig.app

    const {
      fetchProprietesByCoproprietaire, proprietesDataPaginate, total, proprieteProcess, publierPropriete,
    } = useProprietes()
    const { successToast } = useAlertNotification()

    const perPages = [9, 18, 27, 'Tout']
    const perPageValue = ref(9)
    const filters = ref({
      q: '',
      page: 1,
      perPage: perPageValue.value,
    })
    const villaRules = ref('')
    const users = ref([])

    const publishProperty = async data => {
      Swal.fire({
        title: `${data.status === 'Publié' ? 'Retrait' : 'Publication'}`,
        text: `Etes-vous sûr de vouloir ${data.status === 'Publié' ? 'retirer' : 'publier'} cette propriété dans les offres de logements ?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
          cancelButton: 'btn btn-danger btn-sm ml-1',
        },
        cancelButtonText: 'Annuler',
        confirmButtonText: `${data.status === 'Publié' ? 'Retirer' : 'Publier'}`,
      }).then(async result => {
        if (result.isConfirmed) {
          await publierPropriete(data.id)
          await fetchProprietesByCoproprietaire(filters.value, user.current_coownership, user.id)
          // users.value = gestionCoproprietes.value.users
          Swal.fire({

            title: `${data.status === 'Publié' ? 'Retrait' : 'Publication'}`,
            text: `Propriété ${data.status === 'Publié' ? 'retirée' : 'publiée'} avec succès.`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      })
    }
    watch([filters.value, perPageValue], async () => {
      if (perPageValue.value === 'Tout') filters.value.perPage = total.value
      else filters.value.perPage = perPageValue.value
      await fetchProprietesByCoproprietaire(filters.value)
    }, {
      deep: true,
    })

    onMounted(async () => {
      await fetchProprietesByCoproprietaire(filters.value, user.current_coownership, user.id)
    })
    const categorieProprieteColor = tag => {
      if (tag === 'Villa') return 'light-info'
      if (tag === 'Immeuble') return 'light-danger'
      return 'light-primary'
    }

    const copyPixCodeClick = id => {
      navigator.clipboard.writeText(`https://logisconnect.mameribj.org/demande-de-logement/${id}`)
      successToast('Copie', 'Lien copié avec succès')
    }
    return {
      avatarText,
      kFormatter,
      categorieProprieteColor,
      proprietesDataPaginate,
      perPages,
      perPageValue,
      filters,
      proprieteProcess,

      total,
      SITE_URL,
      users,
      villaRules,
      publishProperty,
      copyPixCodeClick,

    }
  },
}
</script>

<style scoped>
@import '../../../../assets/css/estilos2.def.min.css';
</style>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
</style>
